var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cards"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showloader),expression:"showloader"}],staticClass:"inprocessdiv"},[_c('vue-loaders',{staticClass:"ball",attrs:{"name":"ball-beat","color":"#F78320","size":"20px"}})],1),_c('div',{staticClass:"flexrow_green mb50"},[_vm._m(0),_c('div',{staticClass:"flexcircle"},[_c('router-link',{staticClass:"btn btn-vermilion",attrs:{"to":"/newproduct"}},[_vm._v("Add")])],1)]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.photographers,"search-options":{
    enabled: true,
  },"pagination-options":{
    enabled: true,
    perPage: 10,
  },"styleClass":"vgt-table striped bordered","theme":"black-rhino"},on:{"on-row-click":_vm.onRowClick}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subheader_flex"},[_c('h1',{staticClass:"nomargin redhigh"},[_vm._v("Products")])])
}]

export { render, staticRenderFns }