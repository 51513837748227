<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Files Upload</h2>
      </div>
      <!-- <div class="flexcircle">
        <router-link :to="{ name: 'photographers'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div> -->
    </div>
    <div class="cardinner">
      <div class="col-12">
        <label class="mb-3">
          Select Product CAS No
        </label>
        <multiselect
          v-model="selected_game"
          :options="photographers"
          :searchable="true"
          label="casno"
          value = "product_id"
          @select="selectedcas()"
          placeholder="Select CAS No">
        </multiselect>
      </div>
      <div class="col-12 mt-5" v-if="showbuttons">
            <button class="btn btn-green mr-5" @click="showcoadiv">COA</button>
          <button class="btn btn-green mr-5" @click="showmsdsdiv">MSDS</button>
            <button class="btn btn-green " @click="showspecdiv">Specifications</button>
      </div>


      <div class="col-12" v-show="showcoa">
      <form class="ui form" v-on:submit.prevent="addcoadocument">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">

          <div class="col-12 mb20">
            <div class="flexrow ">

              <div class="flexcircle">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-green">Upload COA</button>
                  <input type="file" id="files1" ref="files1" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'   v-on:change="handleFilesUpload1($event)" />
                </div>
              </div>
            </div>
            <div class="clearfix col-12 filebox ">
              <div class="uploadmess text-center" v-if="files.length === 0">
                Please click Upload COA button to add COA file
              </div>
              <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
                <div class="flexrow ">
                  <div class="filename subheader_flex ">{{ file.name }} </div>

                  <div class="file_btn flexcircle">
                    <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
</div>

<div class="col-12" v-show="showmsds">
<form class="ui form" v-on:submit.prevent="addmsdsdocument">
  <div class="errorrow">
    <span class="errorsmall" v-show="error">{{errorreport}}</span>
  </div>
  <div class="row">

    <div class="col-12 mb20">
      <div class="flexrow ">

        <div class="flexcircle">
          <div class="upload-btn-wrapper">
            <button class="btn btn-green">Upload MSDS</button>
            <input type="file" id="files2" ref="files2" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'   v-on:change="handleFilesUpload2($event)" />
          </div>
        </div>
      </div>
      <div class="clearfix col-12 filebox ">
        <div class="uploadmess text-center" v-if="files.length === 0">
          Please click Upload MSDS button to add MSDS file
        </div>
        <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
          <div class="flexrow ">
            <div class="filename subheader_flex ">{{ file.name }} </div>

            <div class="file_btn flexcircle">
              <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 text-right">
        <div class="form-group">
          <button type="submit" class="btn btn-green">Submit</button>
        </div>
      </div>
  </div>
</form>
</div>

<div class="col-12" v-show="showspec">
<form class="ui form" v-on:submit.prevent="addspecdocument">
  <div class="errorrow">
    <span class="errorsmall" v-show="error">{{errorreport}}</span>
  </div>
  <div class="row">

    <div class="col-12 mb20">
      <div class="flexrow ">

        <div class="flexcircle">
          <div class="upload-btn-wrapper">
            <button class="btn btn-green">Upload Specifications</button>
            <input type="file" id="files3" ref="files3" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'   v-on:change="handleFilesUpload3($event)" />
          </div>
        </div>
      </div>
      <div class="clearfix col-12 filebox ">
        <div class="uploadmess text-center" v-if="files.length === 0">
          Please click Upload Specification button to add Specifications file
        </div>
        <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
          <div class="flexrow ">
            <div class="filename subheader_flex ">{{ file.name }} </div>

            <div class="file_btn flexcircle">
              <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 text-right">
        <div class="form-group">
          <button type="submit" class="btn btn-green">Submit</button>
        </div>
      </div>
  </div>
</form>
</div>
<div class="boxsh" v-show="showbuttons">
  <div class="flexrow_green mt20">
    <div class="subheader_green_flex">
      <h2>Documents</h2>
    </div>

  </div>
  <div class="cardinner">
    <div class="row ">
      <div class="col-sm-4 mb20">
        <label> COA:</label>
        <span class="spanblock" v-if="product.coa != null && product.coa != ''">{{coaname}}</span>
        <br /><span class="spanblock" v-if="product.coa == null || product.coa == ''">Not Available</span>
      </div>
      <div class="col-sm-4 mb20">
        <label> MSDS:</label>
      <span class="spanblock" v-if="product.msds != null && product.msds != ''">{{msdsname}}</span>
        <br /><span class="spanblock" v-if="product.msds == null || product.msds == ''">Not Available</span>
      </div>
      <div class="col-sm-4 mb20">
        <label> Print Specifications:</label>
          <span class="spanblock" v-if="product.specifications != null && product.specifications != ''">{{specname}}</span>
        <br /><span class="spanblock" v-if="product.specifications == null || product.specifications == ''">Not Available</span>
      </div>



    </div>
  </div>
</div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'FilesUpload',
  components: { Multiselect },
  data () {
    return {
      showbuttons: false,
      selected_game: '',
      error: false,
      errorreport: '',
      files: [],
      showloader: true,
      photographers: [],
      showcoa: false,
      showmsds: false,
      showspec: false,
      product: '',
      coaname: '',
      specname: '',
      msdsname: ''
    }
  },
  created () {
    this.loadusers()
  },
  methods: {
    selectedcas () {
      this.showloader = true
      this.$http.get('/pgmanagement/pginfo/'+ this.selected_game.product_id).then((res) => {
              this.product = res.data.details[0]
        if (this.product.coa !== null && this.product.coa !== '') {
          this.coaname = this.product.coa.replace("documents\\", "")
        }
        if (this.product.msds !== null && this.product.msds !== '') {
          this.msdsname = this.product.msds.replace("documents\\", "")
        }
        if (this.product.specifications !== null && this.product.specifications !== '') {
          this.specname = this.product.specifications.replace("documents\\", "")
        }
        this.showbuttons = !this.showbuttons
        this.showloader = false
      })
    },
    showcoadiv () {
      this.files = [];
      this.showcoa = true;
      this.showmsds = false;
      this.showspec = false;
    },
    showmsdsdiv () {
      this.files = [];
      this.showcoa = false;
      this.showmsds = true;
      this.showspec = false;
    },
    showspecdiv () {
      this.files = [];
      this.showcoa = false;
      this.showmsds = false;
      this.showspec = true;
    },

    loadusers () {
      this.showloader = true;
      this.photographers = []
      this.$http.get('/pgmanagement/getphotographers').then((res) => {
        this.photographers = res.data.details
        this.showloader = false
      })
    },
    addcoadocument () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
          formData.append('product_id', this.selected_game.product_id)
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }

          this.$http.put('/pgmanagement/uploadcoa',  formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
            if (response.data.success === true) {
                window.location.reload();
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    addmsdsdocument () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
          formData.append('product_id', this.selected_game.product_id)
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }

          this.$http.put('/pgmanagement/uploadmsds',  formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
            if (response.data.success === true) {
                window.location.reload();
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    addspecdocument () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
          formData.append('product_id', this.selected_game.product_id)
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }

          this.$http.put('/pgmanagement/uploadspec',  formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
            if (response.data.success === true) {
              this.$toasted.success('File`s Uploaded Successfully', {
          position: 'top-right',
          duration: 5000,
          theme: 'outline'
        })
                window.location.reload();
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    handleFilesUpload1 () {
      // this.files = []
      let uploadedFiles = this.$refs.files1.files
        console.log(uploadedFiles)
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }

      // for (let i = 0; i < this.files.length; i++) {
      //   let reader = new FileReader();
      //  reader.onload = (e) => {
      //     this.$refs.image[i].src = reader.result;
      //
      //   };
      //
      //   reader.readAsDataURL(this.files[i]);
      // }
    },
    handleFilesUpload2 () {
      // this.files = []
      let uploadedFiles = this.$refs.files2.files
        console.log(uploadedFiles)
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }

      // for (let i = 0; i < this.files.length; i++) {
      //   let reader = new FileReader();
      //  reader.onload = (e) => {
      //     this.$refs.image[i].src = reader.result;
      //
      //   };
      //
      //   reader.readAsDataURL(this.files[i]);
      // }
    },
    handleFilesUpload3 () {
      // this.files = []
      let uploadedFiles = this.$refs.files3.files
        console.log(uploadedFiles)
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }

      // for (let i = 0; i < this.files.length; i++) {
      //   let reader = new FileReader();
      //  reader.onload = (e) => {
      //     this.$refs.image[i].src = reader.result;
      //
      //   };
      //
      //   reader.readAsDataURL(this.files[i]);
      // }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
