<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>New Product</h2>
      </div>
      <div class="flexcircle">
        <router-link :to="{ name: 'PhotoGraphers'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div>
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="addphotographer">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">
          <div class="col-sm-6 mb20">
            <label>Alphabet</label>
              <input type="text" class="form-control" placeholder="Alphabet" name="Alphabet" id="alpha" :class="{ 'is-danger': errors.has( 'Alphabet')}" v-validate="'required'" v-model="product.alphabet">
              <span class="error_text" v-if="errors.has('Alphabet')">{{errors.first('Alphabet')}}</span>
          </div>
          <!-- <div class="col-sm-6 mb20">
            <label>Category</label>
              <input type="text" class="form-control" placeholder="Category" name="category" id="cate" :class="{ 'is-danger': errors.has( 'category')}"  v-validate="'required'"  v-model="product.category">
              <span class="error_text" v-if="errors.has('category')">{{errors.first('category')}}</span>
          </div> -->
          <div class="col-sm-6 mb20">
            <label>Product Name</label>
            <!-- <span class="errorsmall" v-if="emailidavail != ''">{{emailidavail}}</span> -->
              <input type="text" class="form-control" placeholder="Product Name" name="chemical name" id="cname" :class="{ 'is-danger': errors.has( 'chemical name')}"  v-validate="'required'" v-model="product.chemicalname">
              <span class="error_text" v-if="errors.has('chemical name')">{{errors.first('chemical name')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Cas No</label>
              <input type="text" class="form-control" placeholder="Cas No"  name="cas no" id="casno" :class="{ 'is-danger': errors.has( 'cas no')}"  v-validate="'required'" v-model="product.casno">
              <span class="error_text" v-if="errors.has('cas no')">{{errors.first('cas no')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Cat No</label>
              <input type="text" class="form-control" placeholder="Cat No"  name="cat no" id="catno" :class="{ 'is-danger': errors.has( 'cat no')}"  v-validate="'required'" v-model="product.catno">
              <span class="error_text" v-if="errors.has('cat no')">{{errors.first('cat no')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Apperance</label>
              <input type="text" class="form-control" placeholder="Apperance"  name="Apperance" id="apperance" :class="{ 'is-danger': errors.has( 'Apperance')}"  v-validate="'required'" v-model="product.apperance">
              <span class="error_text" v-if="errors.has('Apperance')">{{errors.first('Apperance')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Flash Point</label>
              <input type="text" class="form-control" placeholder="Flash Point"  name="flash point" id="flashpoint" :class="{ 'is-danger': errors.has( 'flash point')}"  v-validate="'required'" v-model="product.fpoint">
              <span class="error_text" v-if="errors.has('flash point')">{{errors.first('flash point')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>HSN code</label>
              <input type="text" class="form-control" placeholder="HSN code"  name="hsn code" id="hsncode" :class="{ 'is-danger': errors.has( 'hsn code')}"  v-validate="'required'"  v-model="product.hsncode">
              <span class="error_text" v-if="errors.has('hsn code')">{{errors.first('hsn code')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>GST (%)</label>
              <input type="text" class="form-control" placeholder="GST"  name="gst" id="gst" :class="{ 'is-danger': errors.has( 'gst')}"  v-validate="'required'"  v-model="product.gst">
              <span class="error_text" v-if="errors.has('gst')">{{errors.first('gst')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Storage</label>
              <input type="text" class="form-control" placeholder="Storage"  name="storage" id="storage" :class="{ 'is-danger': errors.has( 'storage')}"  v-validate="'required'" v-model="product.storage">
              <span class="error_text" v-if="errors.has('storage')">{{errors.first('storage')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Bp.</label>
              <input type="text" class="form-control" placeholder="Bp."  name="Bp." id="bp" :class="{ 'is-danger': errors.has( 'Bp.')}"  v-validate="'required'" v-model="product.bp">
              <span class="error_text" v-if="errors.has('Bp.')">{{errors.first('Bp.')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Mp.</label>
              <input type="text" class="form-control" placeholder="Mp."  name="Mp." id="mp" :class="{ 'is-danger': errors.has( 'Mp.')}"  v-validate="'required'" v-model="product.mp">
              <span class="error_text" v-if="errors.has('Mp.')">{{errors.first('Mp.')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Solubility</label>
              <input type="text" class="form-control" placeholder="Solubility"  name="Solubility" id="solubility" :class="{ 'is-danger': errors.has( 'Solubility')}"  v-validate="'required'"  v-model="product.solubility">
              <span class="error_text" v-if="errors.has('Solubility')">{{errors.first('Solubility')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Density</label>
              <input type="text" class="form-control" placeholder="Density"  name="Density" id="density" :class="{ 'is-danger': errors.has( 'Density')}"  v-validate="'required'"  v-model="product.density">
              <span class="error_text" v-if="errors.has('Density')">{{errors.first('Density')}}</span>
          </div>

          <div class="col-sm-6 mb20">
            <label>Formula</label>
              <input type="text" class="form-control" placeholder="Formula" name="formula" id="formula" :class="{ 'is-danger': errors.has( 'formula')}"  v-validate="'required'" v-model="product.formula">
              <span class="error_text" v-if="errors.has('formula')">{{errors.first('formula')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Molecular Weight</label>
              <input type="text" class="form-control" placeholder="Molecular Weight"  name="molecular weight" id="moleweight" :class="{ 'is-danger': errors.has( 'molecular weight')}"  v-validate="'required'" v-model="product.moleweight">
              <span class="error_text" v-if="errors.has('molecular weight')">{{errors.first('molecular weight')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Purity</label>
              <input type="text" class="form-control" placeholder="Purity"  name="Purity" id="purity" :class="{ 'is-danger': errors.has( 'Purity')}"  v-validate="'required'" v-model="product.purity">
              <span class="error_text" v-if="errors.has('Purity')">{{errors.first('Purity')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>NLT Purity)</label>
              <input type="text" class="form-control" placeholder="NLT Purity"  name="NLT Purity" id="nltpurity" :class="{ 'is-danger': errors.has( 'NLT Purity')}"  v-validate="'required'" v-model="product.nltpurity">
              <span class="error_text" v-if="errors.has('NLT Purity')">{{errors.first('NLT Purity')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Quantities</label>
              <input type="text" class="form-control" placeholder="Quantities"  name="Quantities" id="quantities" :class="{ 'is-danger': errors.has( 'Quantities')}"  v-validate="'required'"  v-model="product.quantities">
              <span class="error_text" v-if="errors.has('Quantities')">{{errors.first('Quantities')}}</span>
              <small>* Enter available quantities with comma separater</small>
          </div>
          <div class="col-sm-6 mb20">
            <label>Prices</label>
              <input type="text" class="form-control" placeholder="Prices"  name="Prices" id="prices" :class="{ 'is-danger': errors.has( 'Prices')}"  v-validate="'required'" v-model="product.prices">
              <span class="error_text" v-if="errors.has('Prices')">{{errors.first('Prices')}}</span>
              <small>* Enter prices for qunatities with comma separater</small>
          </div>
          <div class="col-sm-6 mb20">
            <label>Availability</label>
              <input type="text" class="form-control" placeholder="Availability"  name="Availability" id="availability" :class="{ 'is-danger': errors.has( 'Availability')}"  v-validate="'required'"  v-model="product.availability">
              <span class="error_text" v-if="errors.has('Availability')">{{errors.first('Availability')}}</span>
          </div>
          <div class="col-sm-6 mb20">
            <label>Product Code</label>
              <input type="text" class="form-control" placeholder="Product Code"  name="ProductCode" id="productcode" :class="{ 'is-danger': errors.has( 'ProductCode')}"  v-validate="'required'"  v-model="product.productcode">
              <span class="error_text" v-if="errors.has('ProductCode')">{{errors.first('ProductCode')}}</span>
          </div>
          <!-- <div class="col-sm-6 mb20 pt-4">
            <div class="form-check form-check-inline">
                <label class="form-check-label mr-2" for="inlineCheckbox1">MTO</label>
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" v-model="product.mto">

            </div>
<div class="form-check form-check-inline">
    <label class="form-check-label mr-2" for="inlineCheckbox2">POR</label>
  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" v-model="product.por">

</div>

          </div> -->
          <div class="col-12 mb20">
            <div class="flexrow ">
              <div class="subheader_flex">
                <h2>Structure </h2>
              </div>
              <div class="flexcircle">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-green">Upload Structure</button>
                  <input type="file" id="files" ref="files"  v-on:change="handleFilesUpload($event)" />
                </div>
              </div>
            </div>
            <div class="clearfix col-12 filebox ">
              <div class="uploadmess text-center" v-if="files.length === 0">
                Please click Upload Structure button to add Structure
              </div>
              <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
                <div class="flexrow fixeddim">
                  <!-- <div class="filename subheader_flex ">{{ file.filee.name }} </div> -->
                  <img :ref="'image'" class="img-fluid"/>
                  <div class="file_btn flexcircle">
                    <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewPhotoGrapher',
  data () {
    return {
      error: false,
      errorreport: '',
      product: {
        alphabet: '',
        chemicalname: '',
        casno: '',
        catno: '',
        apperance: '',
        fpoint: '',
        hsncode: '',
        gst: '',
        storage: '',
        bp: '',
        mp: '',
        solubility: '',
        density: '',
        formula: '',
        moleweight: '',
        purity: '',
        nltpurity: '',
        quantities: '',
        prices: '',
        availability: '',
        productcode: ''
      },
        files: [],
      showloader: false
    }
  },
  methods: {
    addphotographer () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }
            formData.append('alphabet', this.product.alphabet);
            formData.append('chemicalname', this.product.chemicalname);
            formData.append('casno', this.product.casno);
            formData.append('catno', this.product.catno);
            formData.append('apperance', this.product.apperance);
            formData.append('fpoint', this.product.fpoint);
            formData.append('hsncode', this.product.hsncode);
            formData.append('gst', this.product.gst);
            formData.append('storage', this.product.storage);
            formData.append('bp', this.product.bp);
            formData.append('mp', this.product.mp);
            formData.append('solubility', this.product.solubility);
            formData.append('density', this.product.density);
            formData.append('formula', this.product.formula);
            formData.append('moleweight', this.product.moleweight);
            formData.append('purity', this.product.purity);
            formData.append('nltpurity', this.product.nltpurity);
            formData.append('quantities', this.product.quantities);
            formData.append('prices', this.product.prices);
            formData.append('availability', this.product.availability);
            formData.append('productcode', this.product.productcode);
          this.$http.post('/pgmanagement/addphotographer',  formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
            if (response.data.success === true) {
                this.$router.push('/products')
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    handleFilesUpload () {
      // this.files = []
      let uploadedFiles = this.$refs.files.files
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
      for (let i = 0; i < this.files.length; i++) {
        let reader = new FileReader();
       reader.onload = (e) => {
          this.$refs.image[i].src = reader.result;
console.log(e)
        };

        reader.readAsDataURL(this.files[i]);
      }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    }
  }
}
</script>

<style lang="css" scoped>

</style>
