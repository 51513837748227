<template>
<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
    <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="boxsh">
    <div class="flexrow_green mt20">
      <div class="subheader_green_flex">
        <h2>Product Info</h2>
      </div>
      <div class="flexcircle">

        <a @click="$router.push('/products')" class="btn btn-gray btn-subheader">Back</a>
        <!-- <button type="button" class="btn btn-vermilion btn-subheader">Add Physician</button> -->
      </div>
    </div>
    <div class="cardinner">
      <div class="row ">
        <div class="col-6">
          <div class="col-sm-12 mb20">
            <label> Product Name:</label>
            <span class="spanblock">{{product.productname}}</span>
          </div>
          <div class="col-sm-12 mb20">
            <label> Cas No:</label>
            <span class="spanblock">{{product.casno}}</span>
          </div>
          <div class="col-sm-12 mb20">
            <img :src="product.structure" alt="image"  class="imagewidth img-fluid" width="300" height="300" />
          </div>
        </div>
        <div class="col-6">
          <h6>Available Packs</h6>
          <table class="table pricetable text-center mt-3" style="width: 100%; " data-aos="fade-up">
              <thead style="background-color: #f58634">
                <tr>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Available Packs</th>
                  <!-- <th> </th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(name, index) in packs.prices" v-bind:key="index">
                  <td>{{
packs.quantities[index] }}</td>
                  <td>{{name}}</td>

                  <td>{{
packs.availabilities[index] }}</td>
                </tr>
              </tbody>
            </table>
        </div>

        <div class="col-12 text-right">

           <button type="button" class="btn btn-green mr-3" name="button" v-on:click="activate(product)" v-if="product.feature == 'no'">Make as Featured Product</button>
          <button type="button" class="btn btn-vermilion" name="button" v-on:click="suspend(product)" v-if="product.feature == 'yes'">Remove From Featured Category</button>

          <router-link :to="{ name: 'editphotographer', params: { eid: product.product_id }}"  class="btn btn-vermilion">Edit Info</router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="boxsh">
    <div class="flexrow_green mt20">
      <div class="subheader_green_flex">
        <h2>Technical Info</h2>
      </div>

    </div>
    <div class="cardinner">
      <div class="row ">
        <div class="col-sm-4 mb20">
          <label> CAT No:</label>
          <span class="spanblock">{{product.catno}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> HSN Code:</label>
          <span class="spanblock">{{product.hsncode}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> GST:</label>
          <span class="spanblock">{{product.gst}} %</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Formula:</label>
          <span class="spanblock">{{product.moleformula}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Flash Point:</label>
          <span class="spanblock">{{product.fpoint}}</span>
        </div>

        <div class="col-sm-4 mb20">
          <label> Molecule Weight:</label>
          <span class="spanblock">{{product.moleweight}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Storage:</label>
          <span class="spanblock">{{product.storage}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Bp:</label>
          <span class="spanblock">{{product.bp}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Mp:</label>
          <span class="spanblock">{{product.mp}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Density:</label>
          <span class="spanblock">{{product.density}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Apperance:</label>
          <span class="spanblock">{{product.appearance}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Purity:</label>
          <span class="spanblock">{{product.purity}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> NLT Purity:</label>
          <span class="spanblock">{{product.nltpurity}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Solubility:</label>
          <span class="spanblock">{{product.solubility}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Product Code:</label>
          <span class="spanblock">{{product.productcode}}</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Featured Product:</label>
          <span class="spanblock">{{product.feature}}</span>
        </div>




      </div>
    </div>
  </div>

  <div class="boxsh">
    <div class="flexrow_green mt20">
      <div class="subheader_green_flex">
        <h2>Documents</h2>
      </div>

    </div>
    <div class="cardinner">
      <div class="row ">
        <div class="col-sm-4 mb20">
          <label> COA:</label><br />
          <a v-if="product.coa != null && product.coa != ''" target="_blank" class="btn p-3" :href="`${$imageUrl}` + product.coa" style="color:#455a64;"><font-awesome-icon icon="download" size="4x" /> <br /><br /><span>{{coaname}}</span></a>
          <br /><span class="spanblock" v-if="product.coa == null || product.coa == ''">Not Available</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> MSDS:</label>
          <a v-if="product.msds != null && product.msds != ''" target="_blank" class="btn p-3" :href="`${$imageUrl}` + product.msds" style="color:#455a64;"><font-awesome-icon icon="download" size="4x" /> <br /><br /><span>{{msdsname}}</span></a>
          <br /><span class="spanblock" v-if="product.msds == null || product.msds == ''">Not Available</span>
        </div>
        <div class="col-sm-4 mb20">
          <label> Print Specifications:</label>
            <a v-if="product.specifications != null && product.specifications != ''" target="_blank" class="btn p-3" :href="`${$imageUrl}` + product.specifications" style="color:#455a64;"><font-awesome-icon icon="download" size="4x" /> <br /><br /><span>{{specname}}</span></a>
          <br /><span class="spanblock" v-if="product.specifications == null || product.specifications == ''">Not Available</span>
        </div>


        <div class="col-12 text-right">

          <router-link :to="{ name: 'StockUpdate'}"  class="btn btn-vermilion">Update Documents</router-link>
        </div>


      </div>
    </div>
  </div>

</div>
</template>

<script>
// import { mapGetters } from 'vuex'
  import _ from 'lodash';
export default {
  name: 'photographer_info',
  data () {
    return {
      product: {
      },
      images: [],
      prices: [],
      quantities: [],
      availabilities: [],
      status: '',
      showloader: true,
      packs: {},
      coaname: '',
      specname: '',
      msdsname: ''

    }
  },
  mounted () {
    this.loadinfo()
  },
  methods: {
    sortArrays(arrays) {
            return _.orderBy(arrays, 'created_at');
        },
    loadinfo () {
      this.$http.get('/pgmanagement/pginfo/' + this.$route.params.id).then((response) => {
        if (response.data.success === true) {
          this.showloader = false
          this.product = response.data.details[0]
          this.prices = this.product.prices.split(',')
          this.quantities = this.product.quantities.split(',')
          if (this.product.availability !== null && this.product.availability !== '') {
            this.availabilities = this.product.availability.split(',')
          }

          this.packs = {
            "prices": this.prices,
            "quantities": this.quantities,
            "availabilities": this.availabilities
          }
          if (this.product.coa !== null && this.product.coa !== '') {
            this.coaname = this.product.coa.replace("documents\\", "")
          }
          if (this.product.msds !== null && this.product.msds !== '') {
            this.msdsname = this.product.msds.replace("documents\\", "")
          }
          if (this.product.specifications !== null && this.product.specifications !== '') {
            this.specname = this.product.specifications.replace("documents\\", "")
          }
        } else {
          this.showloader = false
          this.user = []
        }
      })
    },

    // For suspend the Channel Partner...
      suspend (cpat) {
        let messagee = "Are you sure you want to Remove <b>" + cpat.productname + '</b> from featured category?'
        this.$dialog.confirm(messagee,{
          loader: true,
          okText: "Proceed",
          cancelText: "Cancel",
          html: true
        })
        .then((dialog) => {
          this.$http.put('/pgmanagement/updatefeatureproduct/' + cpat.product_id, {
            feature: "no"
          }).then((res) => {
            console.log(res)
            this.loadinfo()
            dialog.close()
          })
        })
        .catch(() => {
          //console.log(this.$i18n.t('dataarray.xa271'))
        })
      },

    // For activate the Channel Partner...
    activate (cpat) {
      let messagee = "Are you sure you want to  make <b>" + cpat.productname + '</b> as a featured product?'
      this.$dialog.confirm(messagee,{
        loader: true,
        okText: "Proceed",
        cancelText: "Cancel",
        html: true
      })
      .then((dialog) => {
        this.$http.put('/pgmanagement/updatefeatureproduct/' + cpat.product_id, {
          feature: "yes"
        }).then((res) => {
          console.log(res)
          this.loadinfo()
          dialog.close()
        })
      })
      .catch(() => {
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
