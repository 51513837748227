<template>
  <div class="cards ">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mb50" >
      <div class="subheader_flex">
        <!-- <h1 class="nomargin redhigh">Products</h1> -->
      </div>
      <div class="flexcircle">
        <button class="btn btn-vermilion" @click="showfilesdiv = !showfilesdiv">Bulk Update</button>
      </div>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Edit Stock Availablity</h2>
      </div>

    </div>
    <div class="cardinner">
      <div class="col-12" v-show="!showfilesdiv">
        <label class="mb-3">
          Select Product CAS No
        </label>
        <multiselect
          v-model="selected_game"
          :options="photographers"
          :searchable="true"
          label="casno"
          value = "product_id"
          @select="datafilter()"
          placeholder="Select CAS No"
          >
        </multiselect>
      </div>
      <div v-show="showfilesdiv">


      <form class="ui form" v-on:submit.prevent="addphotographer">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">

          <div class="col-12 mb20">
            <div class="flexrow ">

              <div class="flexcircle">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-green">Upload Excel</button>
                  <input type="file" id="files" ref="files" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'   v-on:change="handleFilesUpload($event)" />
                </div>
              </div>
            </div>
            <div class="clearfix col-12 filebox ">
              <div class="uploadmess text-center" v-if="files.length === 0">
                Please click Upload Excel button to add Excel file
              </div>
              <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
                <div class="flexrow ">
                  <div class="filename subheader_flex ">{{ file.name }} </div>
                  <!-- <img :ref="'image'" class="img-fluid"/> -->
                  <div class="file_btn flexcircle">
                    <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
        </div>
      <div class="col-12 mt-5" v-if="showbuttons">
        <form class="ui form" v-on:submit.prevent="updatepersonalinfo">
          <div class="errorrow">
            <!-- <pre>{{errors}}</pre> -->
            <!-- <div class="errorsmall" v-if="signup.submitted && personalinfo.loginid.$error.pattren">Loginid is not valid</span>
              <span class="error errorbatch" ng-show="signup.submitted && personalinfo.loginid.$error.maxlength">Loginid is Exceded 20 Characters </span> -->
            <span class="errorsmall" v-show="error">{{errorreport}}</span>

          </div>
          <div class="row" v-for="(prod, key) in test" v-bind:key = "key">
            <div class="col-sm-4 mb20">
              <label>Quantity</label>
                <input type="text" class="form-control" placeholder="Quantity" name="Quantity" id="quantity" :class="{ 'is-danger': errors.has( 'Quantity')}" v-validate="'required'" v-model="prod.quantity">
                <span class="error_text" v-if="errors.has('Quantity')">{{errors.first('Quantity')}}</span>
            </div>
            <div class="col-sm-4 mb20">
              <label>Price</label>
              <!-- <span class="errorsmall" v-if="emailidavail != ''">{{emailidavail}}</span> -->
                <input type="text" class="form-control" placeholder="Price" name="price" id="price" :class="{ 'is-danger': errors.has( 'price')}"  v-validate="'required'" v-model="prod.price">
                <span class="error_text" v-if="errors.has('price')">{{errors.first('price')}}</span>
            </div>
            <div class="col-sm-4 mb20">
              <label>Availablity</label>
                <input type="text" class="form-control" placeholder="Availablity"  name="availability" id="availability" :class="{ 'is-danger': errors.has( 'availability')}"   v-model="prod.availability">
                <span class="error_text" v-if="errors.has('availability')">{{errors.first('availability')}}</span>
            </div>

          </div>
          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Update</button>
              </div>
            </div>
          </form>
      </div>

    </div>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'StockUpdate',
  components: { Multiselect },
  data () {
    return {
      showbuttons: false,
      showfilesdiv: false,
      selected_game: '',
      error: false,
      errorreport: '',
      files: [],
      quantities: [],
      prices: [],
      availabilities: [],
      showloader: true,
      photographers: [],
      showcoa: false,
      showmsds: false,
      showspec: false,
      products: [],
      filterlist: [],
      test: [],
      product_id: '',
      finalprices: [],
      finalquantites: [],
      finalavail: [],
      finalresultarray: []
      // prod: {
      //   quantity:'',
      //   price: '',
      //   availability: ''
      // }
    }
  },
  created () {
    this.loadusers()
  },
  methods: {

    loadusers () {
      this.showloader = true;
      this.photographers = []
      this.$http.get('/pgmanagement/getphotographers').then((res) => {
        this.photographers = res.data.details
        this.showloader = false
      })
    },

    datafilter () {
      this.test = []
      this.showbuttons = !this.showbuttons
      console.log(this.selected_game)
      this.product_id = this.selected_game.product_id
  this.prices = this.selected_game.prices.split(',')
      this.quantities = this.selected_game.quantities.split(',')
      if (this.selected_game.availability !== null) {
          this.availabilities = this.selected_game.availability.split(',')
      } else {
        for(var i=0; i < this.prices.length; i++) {
          this.availabilities.push(' ')
        }
      }
      this.products = [{"prices": this.prices, "quantities": this.quantities, "availabilities": this.availabilities}]
console.log(this.products)
this.quantities.map((row, idx) => {
  this.test.push({
    "price": this.prices[idx],
    "quantity": row,
    "availability": this.availabilities[idx]
  })
});
console.log(this.test)


    },
    updatepersonalinfo () {

      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
            for(var i=0; i < this.test.length; i++) {
              console.log(this.test[i].price)
              this.finalprices.push(this.test[i].price);
              this.finalquantites.push(this.test[i].quantity);
              this.finalavail.push(this.test[i].availability);
            }
            this.finalresultarray = [this.finalprices, this.finalquantites, this.finalavail]
          // let formData = new FormData()
        console.log(this.finalresultarray)

          this.$http.put('/pgmanagement/updatestock/' + this.product_id,
            {
              available: this.finalresultarray
            }).then((response) => {
            if (response.data.success === true) {
              this.showloader = false
              this.$toasted.success('Stock info updated successfully', {
          position: 'top-right',
          duration: 5000,
          theme: 'outline'
        })
                window.location.reload();
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    addphotographer () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }

          this.$http.post('/pgmanagement/uploadstockfile',  formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
            if (response.data.success === true) {
                this.$router.push('/products')
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    handleFilesUpload () {
      // this.files = []
      let uploadedFiles = this.$refs.files.files
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
      // for (let i = 0; i < this.files.length; i++) {
      //   let reader = new FileReader();
      //  reader.onload = (e) => {
      //     this.$refs.image[i].src = reader.result;
      //
      //   };
      //
      //   reader.readAsDataURL(this.files[i]);
      // }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
