<template>
  <!-- <animated-zoom-in> -->
  <div class="loginbox">
  <div class="logininnerbox">
    <div class="login_logo">
      <img :src="require('@/assets/logo.png')" class="img-fluid">
    </div>
    <div class="boxsh">
      <div class="login_header">
        <h2 class="form-heading">Login </h2>
      </div>

        <form class="form-signin " @submit.prevent="login">

          <div class="login_area">
            <div class="errormess">
              <div class="error" v-if="error">{{ error }}</div>
            </div>
            <div class="mb20">
              <float-label>
                <input v-model="email" type="text" id="inputEmail"  name="email id" class="form-control" placeholder='Email' v-validate="'required'">
              </float-label>
              <div class="error_text" v-if="errors.has('email id')">{{errors.first('email id')}}</div>
            </div>

            <div class="inputfields">
              <float-label>
                <input v-model="password" type="password" name="password" class="form-control" :class="{'is-danger': errors.has('password')}" placeholder='Password' v-validate="'required'">
              </float-label>
              <div class="error_text" v-if="errors.has('password')">{{errors.first('password')}}</div>
            </div>
          </div>
          <button class="btn btn-green btn-block mb20" type="submit">Login</button>
        </form>
    </div>
  </div>

</div>
<!-- </animated-zoom-in> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'LoginPage',
  data () {
    return {
      email: '',
      password: '',
      error: false,
      showloader: false
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ])
  },
  methods: {
    ...mapActions([
      'setToken',
      'setUser'
    ]),
    login () {
      this.showloader = true
      this.$validator.validateAll().then((res) => {
        if (res) {
          this.$http.post('/auth/authenticate', { email: this.email, password: this.password })
            .then((res) => {
              if (res.data.success === true) {
                this.error = false
                this.setToken(res.data.token)
                localStorage.setItem('token', res.data.token)
                this.setUser(res.data.details)
                  this.$router.push('/products')
              } else {
                this.showloader = false
                this.error = "Login failed!"
              }
            })
            .catch(
              // this.showloader = false
            )
        } else {
          this.showloader = false
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
