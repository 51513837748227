<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Excel File Import</h2>
      </div>
      <!-- <div class="flexcircle">
        <router-link :to="{ name: 'photographers'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div> -->
    </div>
    <div class="cardinner">
      <form class="ui form" v-on:submit.prevent="addphotographer">
        <div class="errorrow">
          <span class="errorsmall" v-show="error">{{errorreport}}</span>
        </div>
        <div class="row">

          <div class="col-12 mb20">
            <div class="flexrow ">

              <div class="flexcircle">
                <div class="upload-btn-wrapper">
                  <button class="btn btn-green">Upload Excel</button>
                  <input type="file" id="files" ref="files" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'   v-on:change="handleFilesUpload($event)" />
                </div>
              </div>
            </div>
            <div class="clearfix col-12 filebox ">
              <div class="uploadmess text-center" v-if="files.length === 0">
                Please click Upload Structure button to add Excel file
              </div>
              <div class="file-listing clearfix" v-for="(file, key) in files" v-bind:key="key">
                <div class="flexrow ">
                  <div class="filename subheader_flex ">{{ file.name }} </div>
                  <!-- <img :ref="'image'" class="img-fluid"/> -->
                  <div class="file_btn flexcircle">
                    <button type="button" v-on:click="removeFile( key )" class="btn smallbtn btn-vermilion" name="button">x</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 text-right">
              <div class="form-group">
                <button type="submit" class="btn btn-green">Submit</button>
              </div>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExcelImport',
  data () {
    return {
      error: false,
      errorreport: '',
      files: [],
      showloader: false
    }
  },
  methods: {
    addphotographer () {
      this.showloader = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          let formData = new FormData()
            if (this.files.length !== 0) {
              for (let j = 0; j < this.files.length; j++) {
                  formData.append('files[' + j + ']', this.files[j])
              }
            }

          this.$http.post('/pgmanagement/uploadfile',  formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) => {
            if (response.data.success === true) {
                this.$router.push('/products')
            } else {
              this.showloader = false
              this.error = true
              this.errorreport = response.data.error
            }
          })
        }  else {
          this.showloader = false
        }
      })
    },
    handleFilesUpload () {
      // this.files = []
      let uploadedFiles = this.$refs.files.files
      /*
        Adds the uploaded file to the files array
      */
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
      // for (let i = 0; i < this.files.length; i++) {
      //   let reader = new FileReader();
      //  reader.onload = (e) => {
      //     this.$refs.image[i].src = reader.result;
      //
      //   };
      //
      //   reader.readAsDataURL(this.files[i]);
      // }
    },
    removeFile (key) {
      this.files.splice(key, 1)
    }
  }
}
</script>

<style lang="css" scoped>

</style>
