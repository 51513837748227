<template>

<div class="cards">
  <div class="inprocessdiv" v-show="showloader">
      <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
  </div>
  <div class="flexrow_green mb50" >
    <div class="subheader_flex">
      <h1 class="nomargin redhigh">Products</h1>
    </div>
    <div class="flexcircle">
      <router-link to="/newproduct"  class="btn btn-vermilion">Add</router-link>
    </div>
  </div>
   <vue-good-table
  :columns="columns"
  :rows="photographers"
  :search-options="{
    enabled: true,
  }"
  :pagination-options="{
    enabled: true,
    perPage: 10,
  }"
  styleClass="vgt-table striped bordered" theme="black-rhino" @on-row-click="onRowClick"/>

</div>

</template>

<script>
export default {
  name: 'PhotoGraphers',
  data () {
    return {
      columns: [
        {
          label: 'Chemical Name',
          field: 'productname',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'CAS No',
          field: 'casno',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'HSN Code',
          field: 'hsncode',
          filterOptions: {
            enabled: true,
          },
        }
      ],
      photographers: [],
      showloader: true
    }
  },
  created () {
    this.loadusers()
  },
  methods: {
    onRowClick (params) {
        this.$router.push('/product_info/' + params.row.product_id )
    },
    loadusers () {
      this.photographers = []
      this.$http.get('/pgmanagement/getphotographers').then((res) => {
        this.photographers = res.data.details
        this.showloader = false
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
