import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login Page'
import Newphotographer from '@/components/new_photographer'
import Photographers from '@/components/photo graphers'
// import Users from '@/components/users'
// import Orders from '@/components/orders'
import Photographerinfo from '@/components/photographer_info'
import Editphotographer from '@/components/edit_photographer'
// import AddImages from  '@/components/add_image'
// import Customerinfo from '@/components/customer_info'
// import Orderinfo from '@/components/order_info'
// import EditImageContent from '@/components/edit_imageinfo'
// import Addbiodata from '@/components/add_biodata'
// import Editbiodata from '@/components/edit_biodata'
import Excelimport from '@/components/excel_import.vue'
import FilesUpload from '@/components/filesupload.vue'
import StockUpdate from '@/components/stockupdate.vue'
import ProductsDownload from '@/components/products_export.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'LoginPage',
      component: Login
    },
    {
      path: '/newproduct',
      name: 'NewPhotoGrapher',
      component: Newphotographer
    },
    {
      path: '/products',
      name: 'PhotoGraphers',
      component: Photographers
    },
    {
      path: '/excel_import',
      name: 'ExcelImport',
      component: Excelimport
    },
    {
      path: '/products_export',
      name: 'ProductsExport',
      component: ProductsDownload
    },
    {
      path: '/filesupload',
      name: 'FilesUpload',
      component: FilesUpload
    },
    {
      path: '/stockupdate',
      name: 'StockUpdate',
      component: StockUpdate
    },
    {
      path: '/product_info/:id',
      name: 'photographer_info',
      component: Photographerinfo
    },
    {
      path: '/editproduct/:eid',
      name: 'editphotographer',
      component: Editphotographer
    },
    // {
    //   path: '/addImage/:id',
    //   name: 'addImage',
    //   component: AddImages
    // },
    // {
    //   path: '/customer_info/:id',
    //   name: 'customer_info',
    //   component: Customerinfo
    // },
    // {
    //   path: '/order_info/:id',
    //   name: 'order_info',
    //   component: Orderinfo
    // },
    // {
    //   path: '/editimageinfo/:eid',
    //   name: 'editimagecontent',
    //   component: EditImageContent
    // },
    // {
    //   path: '/addbiodata/:id',
    //   name: 'addbiodata',
    //   component: Addbiodata
    // },
    // {
    //   path: '/editbiodata/:id',
    //   name: 'editbiodata',
    //   component: Editbiodata
    // }
  ]
})
