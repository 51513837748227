import { render, staticRenderFns } from "./excel_import.vue?vue&type=template&id=c4861556&scoped=true&"
import script from "./excel_import.vue?vue&type=script&lang=js&"
export * from "./excel_import.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4861556",
  null
  
)

export default component.exports