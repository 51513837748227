<template>
  <div class="cards boxsh">
    <div class="inprocessdiv" v-show="showloader">
        <vue-loaders name="ball-beat" class="ball"  color="#F78320"   size="20px"></vue-loaders>
    </div>
    <div class="flexrow_green mt20" >
      <div class="subheader_green_flex">
        <h2>Products List Download </h2>
      </div>
      <!-- <div class="flexcircle">
        <router-link :to="{ name: 'photographers'}" class="btn btn-gray btn-subheader">Back</router-link>
      </div> -->
    </div>
    <div class="cardinner">
      <div class="p-5 text-center">
        <button class="btn btn-green p-3" type="button" name="download" @click="exportdata($globalUrl)"><font-awesome-icon icon="file-download" size="1x" /> <span>Products List Download</span></button>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsExport',
  data () {
    return {
      showloader: false
    }
  },
  methods: {
    exportdata (url) {
      console.log(url)

 window.open(url, '_top', 'noreferrer');
 setTimeout(function() {
   this.$toasted.success('Product List downloaded successfully', {
position: 'top-right',
duration: 10000,
theme: 'outline'
})

        }, 2000);

    }
  }
}
</script>
