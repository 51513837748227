/* eslint arrow-body-style: ["error", "always"] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  token: '',
  showmenu: false,
  userDetails: {}
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  // To set token to the store state
  setToken (stateVal, token) {
    stateVal.token = token
  },
  // To set userDetails to the store state
  setUser (stateVal, userDetails) {
    stateVal.userDetails = userDetails
  },
  setMenu (stateVal, menu) {
    stateVal.showmenu = menu
  },
  // To clear all
  clearAll (stateVal) {
    stateVal.token = {}
    stateVal.showmenu = false
    stateVal.userDetails = {}
  }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
// Action to invoke setToken
// To set token to the store state
  setToken: ({ commit }, token) => {
    commit('setToken', token)
  },
  // Action to invoke setStore
  // To set store to the store state
  setUser: ({ commit }, userDetails) => {
    commit('setUser', userDetails)
  },
  // Action to invoke setStore
  // To set store to the store state
  setMenu: ({ commit }, menu) => {
    commit('setMenu', menu)
  },
  // To cleatAll in the store state
  clearAll: ({ commit }, data) => {
    commit('clearAll', data)
  }

}

// getters are functions
const getters = {
  // getter to return to token in store state
  getToken: (stateVal) => {
    return stateVal.token
  },
  // getter to return to store details in store state
  getUser: (stateVal) => {
    //console.log(stateVal)
    return stateVal.userDetails
  },
  // getter to return to store details in store state
  getMenu: (stateVal) => {
    //console.log(stateVal)
    return stateVal.showmenu
  }
}

// A Vuex instance is created by combining the state,
// mutations, actions and getters.
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  // Used to persist the store on reload
  plugins: [createPersistedState()]
})
